import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../layouts'
import PageHeader from '../../components/pageheader'
import CallToAction from '../../components/calltoaction'
import FlexContent from '../../components/flexContent'

const DefaultPage = ({
     data: {
         page,
         navigation,
         cta
     },
     pageContext: {
         locale
     }
 }) => {
    return (
        <Layout locale={locale} navigation={navigation} page={page}>
            <PageHeader locale={locale} page={page} />
            <FlexContent page={page} locale={locale}/>
            <CallToAction cta={cta} type='vertical'/>
        </Layout>
    );
}

export default DefaultPage

export const query = graphql`
    query( $contentful_id : String!, $locale: String) {
         page: contentfulPage( node_locale: { eq: $locale }, contentful_id: { eq: $contentful_id } ) {
            ...PageFragment
        }
        navigation: contentfulNavigation (node_locale: {eq: $locale}) {
            ...NavigationFragment
        }  
        cta: allContentfulCallToAction(filter: {node_locale: {eq: $locale}, pageLocation: {eq: "register"}}) {  
            edges {
                node {
                    ...CallToActionFragment
                }
            }
        }
        slider: allContentfulSlider(filter: {node_locale: {eq: $locale}}) {
            edges {
                node {
                    ...SliderFragment
                }
            }
        }
    }
`

